body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background-color: #1b1b1c;
    overflow: hidden !important;
}

.BackgroundDotsContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;
}

.BackgroundDot {
    position: absolute;
    border-radius: 100%;
}

@keyframes twinkle {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#BackgroundBarDashDivider {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 5px;
    top: 85%;
    background: linear-gradient(to right, rgb(0, 174, 255), rgb(0, 255, 128));
    box-shadow:
        0 0 10px rgba(0, 174, 255, 0.6),
        10px 0 20px rgba(0, 215, 192, 0.4),
        20px 0 30px rgba(0, 255, 128, 0.3);
    animation: FadeInUIGeneral 2s forwards;
}

#MenuBarContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: #1b1b1c;
    top: calc(85% + 5px);
    width: 100%;
    height: calc(15% - 5px);
    animation: FadeInUIGeneral 2s forwards;
}

@keyframes FadeInUIGeneral {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.MenuBarItem {
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    gap: 1rem;
    color: white;
}

#InfoCardMain {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    width: 70%;
    height: 70%;
    top: 7.5%;
    left: 15%;
    border-radius: 10px;
    background-color: rgba(27, 27, 28, .7);
    animation: FadeInUIGeneral 2s forwards;
}

@media ((orientation: landscape) and (max-width: 700px)) or ((orientation: portrait) and (max-height: 700px)) {
    #InfoCardMain {
        flex-direction: column !important;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    #HeadshotContainer {
        width: 5rem !important;
        margin-bottom: 10px;
    }

    .DescriptorSegment {
        width: 89% !important;
    }
}

#HeadshotContainer {
    display: flex;
    margin-left: 5%;
    padding: 3px;
    width: 10rem;
}

#Headshot {
    width: 100%;
    height: 100%;
}

#Resume {
    width: 80%;
    margin-left: 10%;
    margin-top: 10px;
}

.DescriptorSegment {
    margin-left: 5%;
    padding-right: 5%;
    color: #fff;
    width: 60%;
    height: fit-content;
    max-height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
}

.DescriptorSegment::-webkit-scrollbar {
    width: 10px;
}

.DescriptorSegment::-webkit-scrollbar-thumb {
    background-color: mediumspringgreen;
}

.DescriptorSegment::-webkit-scrollbar-track {
    background-color: darkslategray;
}

.DescriptorSegment H2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
}

.DescriptorSegment h1 {
    margin-left: 5%;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 15px;
}

.DescriptorSegment p {
    margin-left: 5%;
    font-size: 12px;
    font-weight: 100;
    margin-bottom: 15px;
}